<template>
  <el-container class="user-protocol">
    <el-header class="header">
      <my-simple-header />
    </el-header>
    <el-main>
      <div class="protocol-content">
        <h1>怀真CMS管理系统使用协议</h1>
        <p v-for="(row, index)  in rows" :key="index">
          <strong v-if="row.strong" v-html="row.text"></strong>
          <span v-else v-html="row.text"></span>
        </p>
        <p style="text-align: right; width: 100%">{{orgName}}</p>
      </div>
      <my-beian />
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: "user-protocol",
  data() {
    const systemName = '怀真CMS管理系统';
    const orgName = '怀真网络工作室';
    return {
      orgName,
      rows: [
        {
          text: "【首部及导言】",
          strong: true,
        },
        {
          text: `欢迎你使用${systemName}!`,
        },
        {
          text: "为使用怀真CMS管理系统(以下简称：本系统或本服务) ,你应当阅读并遵守《怀真CMS管理系统使用协议》(以下简称：本协议) ",
        },
        {
          text: "请你务必审慎阅读、充分理解各条款内容,特别是免除或限制责任的条款,以及开通或使用某项服务的单独协议、规则。",
          strong: true,
        },
        {
          text: `除非你已阅读并接受本协议的所有条款,否则,你无权使用${orgName}提供的服务。你使用${orgName}的本系统,即视为你已阅读并同意上述协议、规则等的约束。你有违反本协议的任何行为时,${orgName}有权依照违反情况,随时单方限制、中止或终止向你提供本系统的使用权,并有权追究你的相关责任。`,
        },
        {
          text: '1. 术语定义',
          strong: true
        },
        {
          text: '<strong>1.1 使用者</strong>: 指经有效注册、申请后,获得本系统使用权的个人、法人或其他组织,也简称为“你”'
        },
        {
          text: `<strong>1.2 怀真CMS管理系统</strong>: 指由${orgName}所拥有、控制、经营的${window.location.origin}网站及网站的下属子页面,以下也简称“管理系统”、“本系统”。`
        },
        {
          text: '<strong>1.3 应用</strong>: 包括但不限于通过应用授权、填写资料(appKey,appSecret) 等方式将接口调用权限开放给本系统的微信公众号,微信服务号,微信小程序,淘宝账号等。'
        },
        {
          text: '<strong>1.4 用户</strong>: 通过你的应用注册、申请或其他方式使用你的应用的个人、法人或其他组织'
        },
        {
          text: `${orgName}、使用者均同意并理解：`,
          strong: true
        },
        {
          text: `(1)  本系统是一个中立的应用管理能力提供者,本协议项下仅向使用者提供通过本系统对自身应用的管理能力和相关中立的技术服务,并且${orgName}有权基于本系统有序运营、健康发展等因素选择使用本系统的使用者。`,
          strong: true
        },
        {
          text: `(2)  使用者的应用由使用者以其自身名义开发或享有合法的运营权利,并独立承担全部责任。${orgName}不会、也不可能参与开发者应用的研发、运营等任何活动,${orgName}也不会对开发者的应用进行任何的修改、编辑或整理等。`,
          strong: true
        },
        {
          text: `(3)  因使用者应用及服务产生的任何纠纷、责任等,以及使用者违反相关法律法规或本协议约定引发的任何后果,均由使用者独立承担责任、赔偿损失,与${orgName}无关。如侵害到${orgName}或他人权益的,开发者须自行承担全部责任和赔偿一切损失。`,
          strong: true
        },
        {
          text: `<strong>1.4 用户数据</strong>: 是指使用者在本系统、应用等中产生的与用户相关的数据,包括但不限于用户提交的语音数据、图像数据、用户操作行为形成的数据等。“用户数据”的所有权及其他相关权利属于${orgName},且是${orgName}的商业秘密,依法属于用户享有的相关权利除外。`
        },
        {
          text: '2.使用者的权利和义务',
          strong: true
        },
        {
          text: '2.1 账号注册',
          strong: true
        },
        {
          text: '2.1.1 你应当通过本提供提供的注册页面完成注册'
        },
        {
          text: '2.1.2 你注册帐号时,应使用你拥有合法使用权的手机号,并遵守相关协议、规则等的约束'
        },
        {
          text: `2.1.3 为保障本服务的合法性及安全性,你不得违反本协议约定将你的帐号用于其他目的,包括但不限于使用你的帐号为第三方应用申请本服务等。否则,${orgName}有权随时单方限制、中止或终止向你及/或你所登记的应用提供本服务,且未经${orgName}同意你及/或你所登记的应用不得再次使用本服务。`
        },
        {
          text: '2.1.4 你注册帐号使用的手机号码,是你登录及使用本服务的凭证。你应当做好手机号码、密码以及进入和管理本服务中的各类产品与服务的口令、密码等的保密措施。因你保密措施不当或你的其他行为,致使上述口令、密码等丢失或泄漏所引起的一切损失和后果,均由你自行承担。'
        },
        {
          text: '2.1.5 你保证：你注册本服务帐号的手机号码号码是合法的。前述全部手机号码在本服务中进行的包括但不限于以下事项：注册本服务帐号、提交相应资质材料、确认和同意相关协议和规则、选择具体服务类别以及进行费用结算等事项,均是你自行或你授权他人进行的行为,对你均有约束力。同时,你承担以前述全部手机号码为标识进行的全部行为的法律责任。'
        },
        {
          text: `2.1.6 若你发现有他人冒用或盗用你的帐号及密码、或任何其他未经你合法授权的情形时,应立即以有效方式通知${orgName}(包括但不限于提供你的身份信息和相关身份资料、相关事实情况及你的要求等) 。${orgName}收到你的有效请求并核实身份后,会根据不同情况采取相应措施。若你提供的信息不完全,导致${orgName}无法核实你的身份或${orgName}无法判断你的需求等,而导致${orgName}无法进行及时处理,给你带来的损失,你应自行承担。同时,${orgName}对你的请求采取措施需要合理期限,对于你通知${orgName}以及${orgName}根据你的有效通知采取措施之前,由于他人行为给你造成的损失,${orgName}不承担任何责任。`
        },
        {
          text: `2.2收费服务`,
          strong: true
        },
        {
          text: '2.2.1 本系统的部分服务是以收费的形式提供的,如您使用收费服务,请遵守相关的转向规则',
          strong: true
        },
        {
          text: `2.2.2 ${orgName}可能根据实际需要对收费服务的收费标准、方式进行修改和变更,${orgName}也可能会对部分免费服务开始收费。前述修改、变更或开始收费前,${orgName}将在相应服务页面进行通知或公告。如果你不同意上述修改、变更或付费内容,则应停止使用该服务。`
        },
        {
          text: '2.3 应用要求',
          strong: true
        },
        {
          text: '2.3.1 你应自行负责你应用的创作、开发、编辑、加工、修改、测试、运营及维护等工作,并且自行承担相应的费用'
        },
        {
          text: '2.3.2 你的应用,应符合相关法规、技术规范或标准等。'
        },
        {
          text: '2.3.3 你的应用在本系统上运营期间,你需向用户提供及时有效的客户服务,客户服务形式包括但不限于通过明确且合理的方式告知用户客户服务渠道、提供QQ/电话等,并自行承担客服费用。'
        },
        {
          text: '2.3.4 你应当在应用中向相关权利人提供投诉途径,确保权利人在认为你侵犯其合法权益时可以向你主张权利。'
        },
        {
          text: `2.3.5 你的申请使用本服务的应用应为自身开发、创作或获得合法授权运营并独立承担法律责任的应用,你不得通过你的帐号为任何第三方应用代为申请、使用本服务,否则,${orgName}有权随时单方限制、中止或终止向你及/或你所登记的应用提供本服务,且未经${orgName}同意你及/或你所登记的应用不得再次使用本服务。`
        },
        {
          text: '2.4 应用运营',
          strong: true
        },
        {
          text: '2.4.1 你不得从事任何包括但不限于以下的违反法规的行为,也不得为以下违反法规的行为提供便利(包括但不限于为你应用的用户的行为提供便利等) ：',
          strong: true
        },
        {
          text: `(1) 反对宪法所确定的基本原则的行为；`,
        },
        {
          text: `(2) 危害国家安全,泄露国家秘密,颠覆国家政权,破坏国家统一的行为；`,
        },
        {
          text: `(3) 损害国家荣誉和利益的行为；`,
        },
        {
          text: `(4) 煽动民族仇恨、民族歧视,破坏民族团结的行为；`,
        },
        {
          text: `(5) 破坏国家宗教政策,宣扬邪教和封建迷信的行为；`,
        },
        {
          text: `(6) 散布谣言,扰乱社会秩序,破坏社会稳定的行为；`,
        },
        {
          text: `(7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的行为；`,
        },
        {
          text: `(8) 侮辱或者诽谤他人,侵害他人合法权益的行为；`,
        },
        {
          text: `(9) 侵害他人知识产权、商业秘密等合法权利的行为；`,
        },
        {
          text: `(10) 恶意虚构事实、隐瞒真相以误导、欺骗他人的行为；`,
        },
        {
          text: `(11) 发布、传送、传播广告信息及垃圾信息；`,
        },
        {
          text: `(12) 其他法律法规禁止的行为。`,
        },
        {
          text: '2.4.2 你不得从事包括但不限于以下行为,也不得为以下行为提供便利(包括但不限于为你的用户的行为提供便利等) ：',
          strong: true
        },
        {
          text: `(1) 在未经过用户同意的情况下,向任何其他用户及他方显示或以其他任何方式提供该用户的任何信息；`,
        },
        {
          text: `(1) 提供跟踪功能,包括但不限于识别其他用户在个人主页上查看、点击等操作行为；`,
        },
        {
          text: `(1) 自动将浏览器窗口定向到其他网页；`,
        },
        {
          text: `(1) 应用中含有计算机病毒、木马或其他恶意程序等任何可能危害、${orgName}或用户权益和终端信息安全等的内容；`,
        },
        {
          text: `(1) 设置或发布任何违反相关法规、公序良俗、社会公德等的功能或内容等；`,
        },
        {
          text: `(1) 提供任何主动设置或引导用户以隐藏、掩饰、更改等方式不披露用户真实身份的通讯、社交、信息交互的服务；`,
        },
        {
          text: `(1) 其他${orgName}认为不应该、不适当的行为、内容。`,
        },
        {
          text: `2.4.3 本服务中可能会使用第三方软件或技术，若有使用，${orgName}保证已经获得合法授权，同时，${orgName}将按照相关法规或约定，对相关的协议或其他文件，进行展示。前述通过各种形式展现的相关协议或其他文件，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，你应当遵守这些要求。否则，因此带来的一切责任你应自行承担。如因本服务使用的第三方软件或技术引发的任何纠纷，由该第三方负责解决。`
        },
        {
          text: `2.5 法律责任`,
          strong: true
        }, {
          text: '2.5.1 你保证：你使用本服务及你的任何行为，不会侵犯任何人的合法权益等。',
          strong: true
        },
        {
          text: `2.5.2 你保证：你使用本服务及你的任何行为，不违反任何相关法规、本协议和相关协议、规则等。否则，你理解并同意：若${orgName}自行发现或根据相关部门的信息、权利人的投诉等发现你可能存在违反前述保证情形的，${orgName}有权根据一般人的认识自己独立判断，以认定你是否存在违反前述保证情形，若${orgName}经过判断认为你存在违反前述保证情形的，${orgName}有权随时单方采取以下一项或多项措施。`,
          strong: true
        },
        {
          text: '(1) 要求你立即更换、修改违反前述保证情形的相关内容；'
        },
        {
          text: '(2) 对存在违反前述保证情形的应用或你名下的全部应用或任何一款应用采取下线措施即终止应用使用本系统的服务；'
        },
        {
          text: '(3) 禁止你今后将你的任何新应用接入开放平台；'
        },
        {
          text: '(4) 追究你的法律责任；'
        },
        {
          text: '(5) 将你的行为对外予以公告；'
        },
        {
          text: `(6) 其他${orgName}认为适合的处理措施。`
        },
        {
          text: `2.5.3 若${orgName}按照上述条款、本协议的其他相关约定或因你违反相关法律的规定，对你或你的应用采取任何行为或措施，所引起的纠纷、责任等一概由你自行负责，造成你损失的，你应自行全部承担，造成${orgName}或他人损失的，你也应自行承担全部责任。`,
          strong: true
        },
        {
          text: `3. ${orgName}的权利和义务`,
          strong: true
        },
        {
          text: `3.1 ${orgName}会根据你选择的服务类型向你提供相应的服务。你理解并同意，基于用户体验、平台规则要求及健康发展等综合因素，${orgName}有权选择提供服务或开展合作的对象，有权决定功能开放、数据接口和相关数据披露的对象和范围，并有权视具体情况中止或终止向存有包括但不限于以下情形的使用者或应用提供本服务：`,
          strong: true
        },
        {
          text: `(1) 违反法律法规或本协议规定的；`
        },
        {
          text: `(2) 影响使用者体验的；`
        },
        {
          text: `(3) 存在安全隐患的；`
        },
        {
          text: `3.2 保护你的信息的安全是${orgName}的一项基本原则，未经你的同意，${orgName}不会向${orgName}以外的任何公司、组织和个人披露、提供你的信息，但下列情形除外：`
        },
        {
          text: '(1) 据本协议或其他相关协议、规则等约定可以提供的；'
        },
        {
          text: '(2) 依据法律法规的规定可以提供的；'
        },
        {
          text: '(3) 行政、司法等政府部门要求提供的；'
        },
        {
          text: `(4) 你同意${orgName}向第三方提供；`
        },
        {
          text: `(5) 为解决举报事件、提起诉讼而需要提供的；`
        },
        {
          text: `(6) 为防止严重违法行为或涉嫌犯罪行为发生而采取必要合理行动所必须提供的。`
        },
        {
          text: `3.3 尽管${orgName}对你的信息保护做了极大的努力，但是仍然不能保证在现有的安全技术措施下，你的信息可能会因为不可抗力或非${orgName}因素造成泄漏、窃取等，由此给你造成损失的，你同意${orgName}可以免责。`
        },
        {
          text: `3.4 ${orgName}可将本协议下的权利和义务的部分或全部转让给他人，如果你不同意${orgName}的该转让，则有权停止使用本协议下服务。否则，视为你对此予以接受。`
        },
        {
          text: `3.5 除了另行有约定外，${orgName}无需为按照本协议享有的权益而向你支付任何费用。`
        },
        {
          text: '4. 关于免责',
          strong: true
        }, {
          text: `4.1 你理解并同意：鉴于网络服务的特殊性，${orgName}有权在无需通知你的情况下根据本系统的整体运营情况或相关运营规范、规则等，可以随时变更、中止或终止部分或全部的服务，若由此给你造成损失的，你同意放弃追究${orgName}的责任。`,
          strong: true
        }, {
          text: `4.2 你理解并同意：为了向你提供更完善的服务，${orgName}有权定期或不定期地对提供本服务的平台或相关设备进行检修、维护、升级等，此类情况可能会造成相关服务在合理时间内中断或暂停的，若由此给你造成损失的，你同意放弃追究${orgName}的责任。`,
          strong: true
        },
        {
          text: `4.3 你理解并同意：${orgName}的服务是按照现有技术和条件所能达到的现状提供的。${orgName}会尽最大努力向你提供服务，确保服务的连贯性和安全性；但${orgName}不能保证其所提供的服务毫无瑕疵，也无法随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。所以你也同意：即使${orgName}提供的服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为${orgName}违约，同时，由此给你造成的数据或信息丢失等损失的，你同意放弃追究${orgName}的责任。`,
          strong: true
        },
        {
          text: `4.4 你理解并同意：在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，${orgName}将努力在第一时间与相关单位配合，及时进行修复，若由此给你造成损失的，你同意放弃追究${orgName}的责任。`,
          strong: true
        },
        {
          text: `4.5 你理解并同意：若由于对以下情形导致的服务中断或受阻，给你造成损失的，你同意放弃追究${orgName}的责任：`,
          strong: true
        },
        {
          text: '(1) 到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；',
          strong: true
        },
        {
          text: `(2) 你或${orgName}的电脑软件、系统、硬件和通信线路出现故障；`,
          strong: true
        },
        {
          text: `(3) 你操作不当；`,
          strong: true
        },
        {
          text: `(4) 你通过非${orgName}授权的方式使用本服务；`,
          strong: true
        },
        {
          text: `(5) 其他${orgName}无法控制或合理预见的情形。`,
          strong: true
        },
        {
          text: '5. 服务的中止或终止',
          strong: true
        },
        {
          text: `5.1 如你书面通知${orgName}不接受本协议或对其的修改，${orgName}有权随时中止或终止向你提供本服务。`
        },
        {
          text: `5.2 因不可抗力因素导致你无法继续使用本服务或${orgName}无法提供本服务的，${orgName}有权随时终止协议。`
        },
        {
          text: `5.3 本协议约定的其他中止或终止条件发生或实现的，${orgName}有权随时中止或终止向你提供本服务。`
        },
        {
          text: `5.4 由于你违反本协议约定，${orgName}依约终止向你提供本服务后，如你后续再直接或间接，或以他人名义注册使用本服务的，${orgName}有权直接单方面暂停或终止提供本服务。`
        },
        {
          text: `5.5 如本协议或本服务因为任何原因终止的，对于你的帐号中的全部数据或你因使用本服务而存储在${orgName}服务器中的数据等任何信息，${orgName}可将该等信息保留或删除，包括服务终止前你尚未完成的任何数据。`,
          strong: true
        },
        {
          text: `5.6 如本协议或本服务因为任何原因终止的，你应自行处理好关于数据等信息的备份以及与你的用户之间的相关事项的处理等，由此造成${orgName}损失的，你应负责赔偿。`,
          strong: true
        },
        {
          text: '6. 关于通知',
          strong: true
        },
        {
          text: `6.1 ${orgName}可能会以网页公告、网页提示、电子邮箱、手机短信、常规的信件传送、你注册的本服务帐号的管理系统内发送站内信等方式中的一种或多种，向你送达关于本服务的各种规则、通知、提示等信息，该等信息一经${orgName}采取前述任何一种方式公布或发送，即视为你已经接受并同意，对你产生约束力。若你不接受的，请你书面通知${orgName}，否则视为你已经接受、同意。`
        },
        {
          text: `6.2 若由于你提供的电子邮箱、手机号码、通讯地址等信息错误，导致你未收到相关规则、通知、提示等信息的，你同意仍然视为你已经收到相关信息并受其约束，一切后果及责任由你自行承担。`
        },
        {
          text: `6.3 你也同意${orgName}或合作伙伴可以向你的电子邮件、手机号码等发送可能与本服务不相关的其他各类信息包括但不限于商业广告等。`
        }, {
          text: `6.4 若你有事项需要通知${orgName}的，应当按照本服务对外正式公布的联系方式书面通知${orgName}。`
        },
        {
          text: `7. 知识产权`,
          strong: true
        },
        {
          text: `7.1 ${orgName}在本服务中提供的信息内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权均归${orgName}所有，依法属于他人所有的除外。除另有特别声明外，${orgName}提供本服务时所依托软件的著作权、专利权及其他知识产权均归${orgName}所有。上述及其他任何${orgName}依法拥有的知识产权均受到法律保护，未经${orgName}书面许可，你不得以任何形式进行使用或创造相关衍生作品。`
        },
        {
          text: `8. 其他`,
          strong: true
        },
        {
          text: `8.1 本协议签订地为中华人民共和国浙江省杭州市上城区。`
        },
        {
          text: `8.2 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。`
        },
        {
          text: `8.3 若你和${orgName}之间发生任何纠纷或争议，首先应友好协商解决；协商不成功的，双方均同意将纠纷或争议提交本协议签订地有管辖权的人民法院解决。`
        },
        {
          text: `8.4 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。（正文完）`
        }
      ],
    };
  },
  components: {
    "my-beian": () => import("@/components/BeiAn"),
    "my-simple-header": () => import("@/components/SimpleHeader"),
  },
};
</script>

<style lang="less">
.user-protocol {
  height: 100%;

  .header {
    padding: 0px 15%;
    line-height: 60px;
    background-color: rgb(84, 92, 100);
    color: #ffffff;
  }

  .protocol-content {
    border: @hair-border-ccc;
    width: 80%;
    margin: 1rem auto;
    padding: 1rem 2rem;
    box-sizing: border-box;

    h1 {
      text-align: center;
    }
  }

  .my-footer {
    border-top: @hair-border-ccc;
  }
}
</style>